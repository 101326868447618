import "./SubmitEntry.scss";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import {
  getPasswordStrength,
  showPassword,
  doPasswordsMatch,
  isBusinessEmail,
} from "../../utils/fx";

import { useState } from "react";
import { connect } from "react-redux";
import { setCurrentUser } from "../../redux/user/user.actions";
import InputField from "../../components/input-field/InputField";
import { industriesOptions, ukCountiesOptions } from "../../data";
import SelectField from "../../components/select-field/SelectField";
import Tooltip from "../../components/tooltip/ToolTip";
import Info from "../../assets/info.png";
import Button from "../../components/button/Button";

const SubmitEntry = ({ setCurrentUser }) => {
  const navigate = useNavigate();
  const [tooltipCarBev, setTooltipCarBev] = useState(false);
  const [tooltipCarPhev, setTooltipCarPhev] = useState(false);
  const [tooltipVanBev, setTooltipVanBev] = useState(false);
  const [tooltipVanPhev, setTooltipVanPhev] = useState(false);
  const [tooltipHgvBev, setTooltipHgvBev] = useState(false);
  const [tooltipHgvPhev, setTooltipHgvPhev] = useState(false);
  const [tooltipHgv, setTooltipHgv] = useState(false);
  const [tooltipSize, setTooltipSize] = useState(false);
  const [tooltipEmail, setTooltipEmail] = useState(false);
  const [tooltipIndustry, setTooltipIndustry] = useState(false);
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [happyTo, setHappyTo] = useState(false);
  const [happyToNumbers, setHappyToNumbers] = useState(false);
  const [companySize, setCompanySize] = useState("");
  const [industry, setIndustry] = useState("");
  const [location, setLocation] = useState("");
  const [petrolCars, setPetrolCars] = useState(0);
  const [petrolVans, setPetrolVans] = useState(0);
  const [electricCars, setElectricCars] = useState(0);
  const [electricVans, setElectricVans] = useState(0);
  const [hybridCars, setHybridCars] = useState(0);
  const [hybridVans, setHybridVans] = useState(0);
  const [petrolOthers, setPetrolOthers] = useState(0);
  const [hybridOthers, setHybridOthers] = useState(0);
  const [electricOthers, setElectricOthers] = useState(0);
  const [comments, setComments] = useState("none");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verifyProcess, setVerifyProcess] = useState(false);
  const [OTP, setOTP] = useState("");
  const [verifying, setVerifying] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_CLIENT_ID,
  });

  const toggleTooltip = (tooltip, isVisible) => {
    tooltip(isVisible);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      companyName === "" ||
      industry === ""
    ) {
      alert("Please fill in all the fields");
      return;
    }

    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      }),
      new CognitoUserAttribute({
        Name: "custom:company_name",
        Value: companyName,
      }),
      new CognitoUserAttribute({
        Name: "custom:happyTo",
        Value: happyTo.toString(),
      }),
      new CognitoUserAttribute({
        Name: "custom:company_size",
        Value: companySize,
      }),
      new CognitoUserAttribute({
        Name: "custom:electric_cars",
        Value: electricCars,
      }),
      new CognitoUserAttribute({
        Name: "custom:electric_vans",
        Value: electricVans,
      }),
      new CognitoUserAttribute({
        Name: "custom:hybrid_cars",
        Value: hybridCars,
      }),
      new CognitoUserAttribute({
        Name: "custom:hybrid_vans",
        Value: hybridVans,
      }),
      new CognitoUserAttribute({
        Name: "custom:hybrid_others",
        Value: hybridOthers,
      }),
      new CognitoUserAttribute({
        Name: "custom:electric_others",
        Value: electricOthers,
      }),
      new CognitoUserAttribute({
        Name: "custom:comments",
        Value: comments,
      }),
      new CognitoUserAttribute({
        Name: "custom:industry",
        Value: industry,
      }),
      new CognitoUserAttribute({
        Name: "custom:happyToNumbers",
        Value: happyToNumbers.toString(),
      }),
    ];
    if (isBusinessEmail(email)) {
      if (password === confirmPassword) {
        userPool.signUp(email, password, attributeList, null, (err, result) => {
          if (err) {
            console.log("err", err);
            alert(err.message);
            return;
          }
          setVerifyProcess(true);
        });
      } else {
        alert("Passwords do not match");
      }
    } else {
      alert("Please enter a business email");
    }
  };

  const verifyAccount = (e) => {
    e.preventDefault();
    setVerifying(true);
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    user.confirmRegistration(OTP, true, (err, data) => {
      if (err) {
        console.log(err);
        alert("Couldn't verify account");
      } else {
        const user = new CognitoUser({
          Username: email,
          Pool: userPool,
        });
        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password,
        });
        user.authenticateUser(authDetails, {
          onSuccess: (data) => {
            setCurrentUser(data);
            navigate(`/profile/${data.idToken.payload.sub}`);
          },
          onFailure: (err) => {
            console.log("onFailure: ", err);
            alert(err.message);
          },
          newPasswordRequired: (data) => {
            console.log("newPasswordRequired: ", data);
          },
        });
      }
    });
  };

  return (
    <div className="container sign-up">
      <div className="logo">
        <a href="/">
          <img src="/blue_logo_transparent.png" alt="logo" width={350} />
        </a>
      </div>
      <h1 className="page-header">Submit your entry</h1>

      {verifyProcess === false ? (
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="fleet-details">
              <h3>
                Your Fleet
                <br />
                <span className="cars-clause">
                  Please don't include vehicles on delivery and only provide
                  fleet numbers for the UK.
                </span>
              </h3>
              <div>
                <h4 className="pink-text">Cars (inc. 4x4)</h4>

                <div className="vehicle-info">
                  <InputField
                    label={`Fully Electric`}
                    type="number"
                    placeholder="0"
                    id="electric-cars"
                    // tooltip={true}
                    // tooltipText="Fully Electric Cars"
                    // isVisible={tooltipCarBev}
                    // setVisible={setTooltipCarBev}
                    // onHover={() => toggleTooltip(setTooltipCarBev, true)}
                    // onLeave={() => toggleTooltip(setTooltipCarBev, false)}
                    onWheel={(e) => e.target.blur()}
                    handleChange={(e) => setElectricCars(e.target.value)}
                  />
                  <InputField
                    label={`Plug-in Hybrid`}
                    type="number"
                    placeholder="0"
                    id="hybrid-cars"
                    onWheel={(e) => e.target.blur()}
                    handleChange={(e) => setHybridCars(e.target.value)}
                    // tooltip={true}
                    // tooltipText="Plug-in Hybrid"
                    // isVisible={tooltipCarPhev}
                    // setVisible={setTooltipCarPhev}
                    // onHover={() => toggleTooltip(setTooltipCarPhev, true)}
                    // onLeave={() => toggleTooltip(setTooltipCarPhev, false)}
                  />
                  
                </div>
              </div>

              <div>
                <h4 className="pink-text">Vans</h4>
                <div className="vehicle-info">
                  <InputField
                    label={`Fully Electric`}
                    type="number"
                    id="electric-vans"
                    placeholder="0"
                    onWheel={(e) => e.target.blur()}
                    handleChange={(e) => setElectricVans(e.target.value)}
                    // tooltip={true}
                    // tooltipText="Fully Electric Cars"
                    // isVisible={tooltipVanBev}
                    // setVisible={setTooltipVanBev}
                    // onHover={() => toggleTooltip(setTooltipVanBev, true)}
                    // onLeave={() => toggleTooltip(setTooltipVanBev, false)}
                  />
                  <InputField
                    label={`Plug-in Hybrid`}
                    type="number"
                    id="hybrid-vans"
                    placeholder="0"
                    onWheel={(e) => e.target.blur()}
                    handleChange={(e) => setHybridVans(e.target.value)}
                    // tooltip={true}
                    // tooltipText="Plug-in Hybrid"
                    // isVisible={tooltipVanPhev}
                    // setVisible={setTooltipVanPhev}
                    // onHover={() => toggleTooltip(setTooltipVanPhev, true)}
                    // onLeave={() => toggleTooltip(setTooltipVanPhev, false)}
                  />
                  
                </div>
              </div>
              <div>
                <div className="tooltip-container">
                  <div className="tooltip-header">
                    <h4 className="pink-text">Other</h4>
                    <div className="tooltip-container">
                      <img
                        src={Info}
                        alt="info-tooltip"
                        width={15}
                        onMouseEnter={() => toggleTooltip(setTooltipHgv, true)}
                        onMouseLeave={() => toggleTooltip(setTooltipHgv, false)}
                      />
                    </div>
                    {tooltipHgv && (
                      <div className="tooltip-others">
                        For example: HGVs, trucks, cement mixers, road sweepers,
                        skip lifters and more
                      </div>
                    )}
                  </div>
                </div>
                <div className="vehicle-info">
                  <InputField
                    label={`Fully Electric`}
                    type="number"
                    id="electric-others"
                    placeholder="0"
                    onWheel={(e) => e.target.blur()}
                    handleChange={(e) => setElectricOthers(e.target.value)}
                    // tooltip={true}
                    // tooltipText="Fully Electric Cars"
                    // isVisible={tooltipHgvBev}
                    // setVisible={setTooltipHgvBev}
                    // onHover={() => toggleTooltip(setTooltipHgvBev, true)}
                    // onLeave={() => toggleTooltip(setTooltipHgvBev, false)}
                  />
                  <InputField
                    label={`Plug-in Hybrid`}
                    type="number"
                    id="hybrid-others"
                    placeholder="0"
                    onWheel={(e) => e.target.blur()}
                    handleChange={(e) => setHybridOthers(e.target.value)}
                    // tooltip={true}
                    // tooltipText="Plug-in Hybrid"
                    // isVisible={tooltipHgvPhev}
                    // setVisible={setTooltipHgvPhev}
                    // onHover={() => toggleTooltip(setTooltipHgvPhev, true)}
                    // onLeave={() => toggleTooltip(setTooltipHgvPhev, false)}
                  />
                  
                </div>
              </div>
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="happy-to-numbers"
                  onChange={(e) => {
                    setHappyToNumbers(e.target.checked.toString());
                  }}
                />
                <label htmlFor={"happy-to-numbers"}>
                  I'm happy for Rightcharge and Greenfleet to publish our
                  electric vehicle numbers if we're a leading organisation
                  (optional)
                </label>
              </div>
            </div>
            <div className="company-details">
              <h3>Company Details</h3>
              <div>
                <InputField
                  label={`Company Name`}
                  type="text"
                  id="company-name"
                  handleChange={(e) => setCompanyName(e.target.value)}
                />
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="happy-to"
                    onChange={(e) => {
                      setHappyTo(e.target.checked.toString());
                    }}
                  />
                  <label htmlFor={"happy-to"}>
                    I'm happy for Rightcharge and Greenfleet to publish our
                    company name if we're a leading organisation (optional)
                  </label>
                </div>
                <SelectField
                  label={`Industry/Sector`}
                  id="industry"
                  options={industriesOptions}
                  handleChange={(e) => setIndustry(e.target.value)}
                  tooltip={true}
                  isVisible={tooltipIndustry}
                  setVisible={setTooltipIndustry}
                  onHover={() => toggleTooltip(setTooltipIndustry, true)}
                  onLeave={() => toggleTooltip(setTooltipIndustry, false)}
                />

                <div className="regional-national">
                  <div className="label-with-tooltip">
                    <label>Do you consider yourself a:</label>
                    <div className="tooltip-container">
                      <img
                        src={Info}
                        alt="info-tooltip"
                        width={15}
                        onMouseEnter={() => toggleTooltip(setTooltipSize, true)}
                        onMouseLeave={() =>
                          toggleTooltip(setTooltipSize, false)
                        }
                      />
                      {tooltipSize && (
                        <div className="tooltip">
                          We’ll enter you into the national or regional award
                          categories
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="radio-buttons">
                    <label>
                      <input
                        type="radio"
                        id="regional-chabusiness"
                        name="company-size"
                        value="Regional Business"
                        onChange={(e) => setCompanySize(e.target.value)}
                      />
                      Regional Business
                    </label>
                  </div>
                  <div className="radio-buttons">
                    <label>
                      <input
                        type="radio"
                        id="national-business"
                        name="company-size"
                        value="National Business"
                        onChange={(e) => setCompanySize(e.target.value)}
                      />
                      National Business
                    </label>
                  </div>
                </div>
                <div className="comments">
                  <label>Comments (optional)</label>
                  <textarea
                    className="txtarea"
                    rows="3"
                    placeholder="Please detail any achievements of your fleet - e.g. have you electrified a really exciting vehicle type"
                    name="Text"
                    id="Text"
                    onChange={(e) => {
                      setComments(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="login-details">
              <h3>Your Details</h3>
              <p></p>
              <div>
                <InputField
                  label={`Business Email`}
                  type="email"
                  id="email"
                  handleChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  tooltip={true}
                  tooltipText="We require your business email to verify your account."
                  isVisible={tooltipEmail}
                  setVisible={setTooltipEmail}
                  onHover={() => toggleTooltip(setTooltipEmail, true)}
                  onLeave={() => toggleTooltip(setTooltipEmail, false)}
                />

                <InputField
                  label={`Password`}
                  type="password"
                  id="password"
                  handleChange={(e) => {
                    setPassword(e.target.value);
                    setIsTouched(true);
                  }}
                />
                {isTouched && (
                  <div id="password-strength-indicator">
                    <div className={getPasswordStrength(password)}>
                      {" "}
                      {getPasswordStrength(password).toUpperCase()}
                    </div>
                  </div>
                )}
                <div>
                  <p className="cars-clause">
                    Password must be at least 8 characters long and contain at
                    least one uppercase letter, one lowercase letter and one
                    number.
                  </p>
                </div>
                <InputField
                  label={`Confirm Password`}
                  type="password"
                  id="confirm-password"
                  handleChange={(e) => setConfirmPassword(e.target.value)}
                />

                {confirmPassword !== "" && (
                  <div
                    id="password-match-indicator"
                    className={
                      doPasswordsMatch(password, confirmPassword)
                        ? "strong"
                        : "weak"
                    }
                  >
                    {doPasswordsMatch(password, confirmPassword)
                      ? "Passwords Match"
                      : "Passwords Do Not Match"}
                  </div>
                )}

                <div className="show-password">
                  <input
                    type="checkbox"
                    id="show-password"
                    name="show-password"
                    onClick={showPassword}
                  />
                  <label htmlFor="show-password">Show Password</label>
                </div>
              </div>
            </div>

            <div className="submit-button">
              <Button label={"Submit"} isSubmit={true} />
            </div>
          </form>
          <span className="marketing-communication">
            By signing up, I agree to receive updates from Rightcharge about the
            Electric Fleet Race and useful services from Rightcharge. I agree
            for my data to be shared with the Offical Media Partner Greenfleet
          </span>
          <p>
            If you need help or have a question, please contact us at{" "}
            <a href="mailto:hello@rightcharge.co.uk">hello@rightcharge.co.uk</a>
          </p>
        </div>
      ) : (
        <div className="form-container">
          <form onSubmit={verifyAccount}>
            <p>
              We have sent an email to your email address with a verification
              code. Please enter the verification code below to verify your
              account.
            </p>
            <InputField
              label={`Code`}
              type="text"
              id="code"
              handleChange={(e) => setOTP(e.target.value)}
            />
            <br />
            <Button
              label={verifying ? "Verifying..." : "Verify"}
              isSubmit={true}
            />
          </form>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(null, mapDispatchToProps)(SubmitEntry);
